@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";

.RichText {
    display: flex;
    align-items: center;
    padding: 2rem !important;
    font-size: 1.3rem;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        padding: 4rem !important;
    }

    // Transparent bubbles
    //&::before {
    //    content: "";
    //    position: absolute;
    //    top: -10rem;
    //    right: -10rem;
    //    width: 65%;
    //    height: auto;
    //    aspect-ratio: 1;
    //    border-radius: 50%;
    //    background: radial-gradient(circle at 0 0px, #fff, rgba(255,255,255,0) 40%);
    //    z-index: 1; //z-index
    //    opacity: .5;
    //    pointer-events: none;
    //    transform: rotate(270deg);
    //}
}

.cao-col-left .RichText {
    //&::before {
    //    left: -10rem;
    //    transform: rotate(170deg);
    //}
}

.cao-col-right .RichText {
    //Default :)
}
